import React from "react"
import { graphql } from "gatsby"
import useScript from "../../hooks/useScript"

import Layout from "../../components/Layout/Layout"
import Lightbox from "../../components/Lightbox/Lightbox"
import SEO from "../../components/seo"
import BuyBox from "../../components/BuyBox/BuyBox"
import { Link } from "gatsby"
// import Share from "../../components/Share/Share"

import styles from "./theme.module.css"

const Theme = ({ data, pageContext, location }) => {
  // eslint-disable-next-line no-unused-vars
  const [paddleLoaded, paddleError] = useScript(
    "https://cdn.paddle.com/paddle/paddle.js"
  )
  if (paddleLoaded) {
    window.Paddle.Setup({ vendor: 111779 })
  }

  const headerAttrs = {
    title: "Modern Account",
    description:
      "Clean, responsive and customizable Keycloak Account Console theme.",
  }

  return (
    <Layout location={location} headerAttrs={headerAttrs} size="lg">
      <SEO
        title={headerAttrs.title}
        description={headerAttrs.description}
        keywords={","}
      />

      <img
        src={data.logo.childImageSharp.fluid.originalImg}
        alt={
          "Modern Account is a responsive and clean Keycloak Account Console theme."
        }
        className={styles.banner}
      />
      <BuyBox paddleLoaded={paddleLoaded} productId={746826}>
        <span className={styles.priceLabel}>
          Buy Modern Account now for only{" "}
        </span>
        <span className={styles.priceAmount}>€39 + VAT</span>
      </BuyBox>

      <h2>Features</h2>
      <p>
        <strong>Modern Account</strong> is an{" "}
        <Link to={`/faq#theme-types`}>account console theme</Link> for Keycloak.
      </p>
      <p>
        It's <span className={styles.bold}>responsive</span> and{" "}
        <span className={styles.bold}>tested on multiple devices</span> of
        different screen size.
      </p>
      <p>
        <strong>Modern Account</strong> is{" "}
        <span className={styles.bold}>customizable</span>, following the
        instructions in the included documentation you can change the theme logo
        and the primary color.
      </p>
      <p>
        Purchasing the theme you get a 14 days money back guarantee and six
        months of <Link to={`/faq#basic-support`}>basic support</Link>.
      </p>

      <h2>Why should I buy?</h2>
      <p>
        <span className={styles.bold}>
          Building a Keycloak theme is complex
        </span>
        . Many pages are not visibile unless you setup Keycloak in a specific
        way and enable them. To develop a theme you would need to disable the
        theme cache in Keycloak and learn how to configure a new theme, how
        assets need to be declared, how FreeMarker templates works.
      </p>
      <p>
        With <strong>Modern Account</strong> you get a high quality theme in
        minutes, this way you can leave the complexity behind and focus on more
        important things.
      </p>

      <h2>Which pages does it includes?</h2>
      <p>
        <strong>Modern Account</strong> includes the following styled pages:
      </p>

      <ul>
        <li>Account console dashboard</li>
        <li>Personal info page</li>
        <li>Signing in page</li>
        <li>Device Activity page</li>
        <li>Linked accounts page</li>
        <li>Applications page</li>
      </ul>

      <h2>Screenshots</h2>
      <Lightbox screenshots={data.screenshots.edges} />

      <h2>What do you get</h2>
      <p>
        By purchasing <strong>Modern Account</strong> you get:
      </p>
      <ul>
        <li>A package ready to be deployed on your Keycloak installation</li>
        <li>Documentation about the themes customization</li>
        <li>Documentation about the themes installation</li>
        <li>Six months of basic support</li>
      </ul>

      <BuyBox paddleLoaded={paddleLoaded} productId={746826}>
        <span className={styles.priceLabel}>
          Buy Modern Account now for only{" "}
        </span>
        <span className={styles.priceAmount}>€39 + VAT</span>
      </BuyBox>
    </Layout>
  )
}

export default Theme

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        siteUrl
        social {
          twitter
        }
      }
    }
    logo: file(absolutePath: { regex: "/modern_account_banner_alt.jpg/" }) {
      childImageSharp {
        fluid {
          originalImg
        }
      }
    }
    screenshots: allFile(
      filter: {
        name: { regex: "/^ma/" }
        sourceInstanceName: { eq: "screenshots" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
